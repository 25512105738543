import { CSSProperties, memo } from 'react'
import { APP_VERSION } from '../../consts/version'
import { isDevEnv } from '../../utils/env'

interface StatusBarProps {
    email: string
    shortName: string
}

export const StatusBar = memo<StatusBarProps>(({ email, shortName }) => {
    const styles = {
        fontSize: '0.75rem',
        position: 'fixed',
        right: '.5rem',
        color: '#555',
        zIndex: 1,
        top: 0,
    } as CSSProperties

    const ENV = isDevEnv ? process.env.REACT_APP_FIREBASE_CONFIG : undefined

    const label = [ENV, email.split('@').shift(), shortName, APP_VERSION]
        .filter((s) => !!s)
        .join(' • ')

    return (
        <span data-testid="status-bar" style={styles}>
            {label}
        </span>
    )
})
