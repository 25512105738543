import { Country } from '../types/country'

export const isDevEnv =
    process.env.REACT_APP_FIREBASE_CONFIG === 'staging' ||
    process.env.REACT_APP_FIREBASE_CONFIG === 'development' ||
    process.env.REACT_APP_FIREBASE_CONFIG === 'localhost'

export const COUNTRY = (process.env.REACT_APP_COUNTRY || 'GB') as Country

export const SALEOR_URL = process.env.REACT_APP_SALEOR_URL || ''
