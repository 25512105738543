import type { WarehouseConfig } from '@quickcommerceltd/zapp-app-utils'
import { FirestoreCollection } from '@quickcommerceltd/zapp-app-utils/build/types/FirestoreCollection'
import { logEvent } from 'firebase/analytics'
import { signInWithCustomToken } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { useApolloProviderContext } from '../GQL/ApolloClientProvider'
import { isUserDetails, UserDetails } from '../types/UserDetails'
import dataStore, { setLocalUserData } from '../utils/dataStore'
import { setDeviceStatus } from '../utils/deviceStatus'
import { COUNTRY } from '../utils/env'
import { log } from '../utils/logHelper'
import { auth, db, zimAnalytics, zimBackendAuth, zimFunctions } from '.'

/**
 * Hook for email password login
 */
export const useLogin = () => {
    const [loginState, setLoginState] = useState<
        | 'submitting'
        | 'loggedin'
        | 'not-loggedin'
        | 'not-allowed'
        | 'needs-sign-up'
    >('not-loggedin')
    const { setApolloAccessToken } = useApolloProviderContext()
    const [errorState, setErrorState] = useState('')

    const login = async (email: string, password: string): Promise<void> => {
        setLoginState('submitting')

        try {
            const login = httpsCallable<any, any>(zimFunctions, 'api-iam-login')

            const {
                data: { id, zimBackendToken, sharedBackendToken, graphQLToken },
            } = await login({ email, password })

            await Promise.all([
                signInWithCustomToken(zimBackendAuth, zimBackendToken),
                signInWithCustomToken(auth, sharedBackendToken),
            ])

            setApolloAccessToken(graphQLToken)

            const userDetailsDoc = await getDoc(
                doc(db, `${COUNTRY.toLowerCase()}_users`, id)
            )

            if (userDetailsDoc.exists()) {
                const userDetails = userDetailsDoc.data() as UserDetails
                if (isUserDetails(userDetails)) {
                    setLocalUserData({
                        userDetails,
                    })
                }

                // setting up warehouse
                await getDoc(
                    doc(
                        db,
                        FirestoreCollection.WAREHOUSE_CONFIGS,
                        userDetails.warehouseId
                    )
                ).then((doc) => {
                    const warehouse = doc.data() as WarehouseConfig
                    if (warehouse) {
                        dataStore.setWarehouse(warehouse)
                    }
                })

                setLocalUserData({
                    uid: id as string,
                    email,
                    accessToken: graphQLToken as string,
                })
                await setDeviceStatus({
                    isOnline: true,
                    pushToken: undefined,
                    userId: id,
                    userEmail: userDetails.email,
                    userName: userDetails.name,
                    warehouseId: userDetails.warehouseId,
                })
                logEvent(zimAnalytics, 'login', {})
                setLoginState('loggedin')
            } else {
                setLoginState('needs-sign-up')
            }
        } catch (error) {
            setLoginState('not-loggedin')
            log('login', 'error', error)
            if (error instanceof Error) {
                setErrorState(error.message)
            }
        }
    }
    return {
        loginState,
        login,
        errorState,
    }
}
