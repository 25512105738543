import { signOut } from 'firebase/auth'
import { createBrowserHistory } from 'history'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../consts/Routes'
import { useAppState } from '../Containers/AppState'
import { setLocalUserData } from '../utils/dataStore'
import { setDeviceStatus } from '../utils/deviceStatus'
import { log } from '../utils/logHelper'
import { auth, zimBackendAuth } from './'

export const useLogout = () => {
    const history = useHistory()
    const { user, userDetails, logoutUser } = useAppState()
    const logout = () => {
        log('auth-logout', 'logout clicked', {
            email: auth.currentUser?.email,
        })
        signOut(auth)
        signOut(zimBackendAuth)
        setLocalUserData()
        setDeviceStatus({
            isOnline: false,
            pushToken: undefined,
            userId: user?.uid,
            userEmail: userDetails?.email,
            userName: userDetails?.name,
            warehouseId: userDetails?.warehouseId,
        })
        /**
         * We need to reset AppStateContext
         */
        logoutUser()
        history.push(ROUTES.LOGIN)
    }

    return {
        logout,
    }
}

export function logout() {
    const history = createBrowserHistory()
    signOut(auth)
    signOut(zimBackendAuth)
    setLocalUserData()
    history.push(ROUTES.LOGIN)
}
