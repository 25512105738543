import { Box, Button, makeStyles } from '@material-ui/core'

export const ReloadButton = () => {
    const useStyles = makeStyles(() => ({
        reload: {
            color: '#AAA',
        },
        footer: {
            backgroundColor: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
        },
    }))

    const classes = useStyles()
    return (
        <Box className={classes.footer}>
            <Button
                className={classes.reload}
                variant="text"
                onClick={() => window.location.reload()}
            >
                reload
            </Button>
        </Box>
    )
}
