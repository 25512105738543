//TODO 3: Write tests for this
//TODO 4: Move this file to utils repo
import * as yup from 'yup'
import { Country } from './country'

export interface UserDetails {
    email: string
    isActive: boolean
    name: string
    superVisorName: string
    userRole: string
    warehouseId: string
    country: Country
    createdAt?: number
    updatedAt?: number
}

export const UserDetailsSchema = yup.object().shape({
    email: yup.string().required(),
    isActive: yup.boolean().required(),
    name: yup.string().required(),
    superVisorName: yup.string().required(),
    userRole: yup.string().required(),
    warehouseId: yup.string().required(),
})

export function isUserDetails(obj: UserDetails) {
    return UserDetailsSchema.isValidSync(obj)
}
export enum EUserRole {
    procurementUser = 'procurementUser',
    superPowerUser = 'superPowerUser',
    powerUser = 'powerUser',
    warehouseManager = 'warehouseManager',
    warehouseWorker = 'warehouseWorker',
}
