import type {
    OrderDetails,
    WarehouseConfig,
} from '@quickcommerceltd/zapp-app-utils'
import { setUserProperties } from 'firebase/analytics'
import { v4 } from 'uuid'
import { zimAnalytics } from '../firebase'
import { defaultWarehouse } from '../mocks/warehouses'
import { Product } from '../types/Product'
import { SKULocation } from '../types/SKULocation'
import { UserDetails } from '../types/UserDetails'
import { attachTypeIntoWarehouse } from './dc'

/**
 * This is just an interim solution until we have proper state management.
 * I would propose ContextAPI, Overmind, MobX or Jotai
 */

// userDetails are stored in the loccalStorage to maintain the session

const KEYS = {
    PRODUCTS: 'products',
    ORDER: 'orderDetail',
    WAREHOUSE: 'warehouse',
    USER: 'userDetails',
    SKU_LOCATIONS: 'skuLocations',
    DEVICE_ID: 'deviceId',
}

export interface LocalUserData {
    accessToken: string
    uid: string
    email: string
    userDetails?: Partial<UserDetails>
}

export const ensureDeviceId = (): string => {
    let deviceId = localStorage.getItem(KEYS.DEVICE_ID)

    if (!deviceId) {
        deviceId = v4()
        localStorage.setItem(KEYS.DEVICE_ID, deviceId)
    }

    return deviceId
}

const deviceId = ensureDeviceId()
export const getDeviceId = () => deviceId

export const getProducts = (): Product[] => {
    const data = localStorage.getItem(KEYS.PRODUCTS) || '[]'
    const products: Product[] = JSON.parse(data)

    return products
}

export const setProducts = (products: Product[]) => {
    localStorage.setItem(KEYS.PRODUCTS, JSON.stringify(products))
}

export const getOrderDetails = () => {
    const data = localStorage.getItem(KEYS.ORDER)
    const orderDetails: OrderDetails | undefined = data
        ? JSON.parse(data)
        : undefined

    return orderDetails
}

export const setOrderDetails = (orderDetails: OrderDetails) => {
    localStorage.setItem(KEYS.ORDER, JSON.stringify(orderDetails))
}

export const setWarehouse = (warehouse?: WarehouseConfig) => {
    setUserProperties(zimAnalytics, {
        warehouse: warehouse?.id,
    })

    if (warehouse) {
        localStorage.setItem(
            KEYS.WAREHOUSE,
            JSON.stringify(attachTypeIntoWarehouse(warehouse))
        )
    }
}

export const getWarehouse = (): WarehouseConfig => {
    try {
        const data = localStorage.getItem(KEYS.WAREHOUSE)
        return data ? JSON.parse(data) : defaultWarehouse
    } catch {
        return defaultWarehouse
    }
}

export const setLocalUserData = (userDetails?: Partial<LocalUserData>) => {
    const deviceId = localStorage.getItem(KEYS.DEVICE_ID)

    if (userDetails) {
        const storedDetails = getLocalUserData()
        const newObj = { ...storedDetails, ...userDetails }

        if (userDetails.userDetails) {
            newObj.userDetails = {
                ...storedDetails?.userDetails,
                ...userDetails.userDetails,
            }
        }

        localStorage.setItem(KEYS.USER, JSON.stringify(newObj))
    } else {
        // this will remove all keys
        localStorage.clear()
        sessionStorage.clear()

        // add back the device id
        if (deviceId) {
            localStorage.setItem(KEYS.DEVICE_ID, deviceId)
        }
    }
}

export const getLocalUserData = () => {
    const data = localStorage.getItem(KEYS.USER)
    const userData: LocalUserData | undefined = data
        ? JSON.parse(data)
        : undefined

    return userData
}

export const setSKULocations = (skuLocations: SKULocation[]) => {
    localStorage.setItem(KEYS.SKU_LOCATIONS, JSON.stringify({ skuLocations }))
}

export const getSKULocations = (): SKULocation[] => {
    const skuLocations = JSON.parse(
        localStorage.getItem(KEYS.SKU_LOCATIONS) || '{}'
    )
    return skuLocations.skuLocations
}

export const clear = () => {
    localStorage.removeItem(KEYS.ORDER)
    localStorage.removeItem(KEYS.PRODUCTS)
    localStorage.removeItem(KEYS.SKU_LOCATIONS)
}

const dataStore = {
    getDeviceId,
    getProducts,
    setProducts,
    getOrderDetails,
    setOrderDetails,
    setWarehouse,
    getWarehouse,
    getSKULocations,
    clear,
}

export default dataStore
