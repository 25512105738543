import { useContext } from 'react'
import { AppStateContext } from './context'
import { AppContextInterface } from './types'

export const useAppState = (): AppContextInterface => {
    const context = useContext(AppStateContext)
    if (context === null) {
        throw new Error(
            'useAppState can only be used inside <AppStateProvider>'
        )
    }
    return context
}
