import { doc, DocumentReference } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../firebase'
import { GenericConverter } from '../../../firebase/genericConverter'
import { AppContextInterface } from '../types'

type UserDetails = AppContextInterface['userDetails']

const converter = new GenericConverter<any>()

export const useUserDetails = (
    userId: string | undefined,
    userCountry: string
): [UserDetails | null] => {
    const query: DocumentReference<UserDetails> = doc(
        db,
        `${userCountry}_users/${userId}`
    ).withConverter(converter)

    const [userDetails = null] = useDocumentData(userId ? query : undefined)

    return [userDetails]
}
