import { onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { getLocalUserData } from '../utils/dataStore'
import { auth } from './'

/**
 * Custom hook to check to get the current firebase user
 */

interface StateInterface {
    user: User | undefined
    isLoading: boolean
}

export const useUser = (
    fn?: () => void
): StateInterface & { isLoggedIn: boolean } => {
    const [state, setState] = useState<StateInterface>({
        user: auth.currentUser ?? undefined,
        isLoading: true,
    })

    useEffect(() => {
        const observer = onAuthStateChanged(auth, (user) => {
            /**
             * @isLoading
             * At this point we need to EXPLICITY set 'isLoading' into `false`
             * because `WithCheckLogin` and `FeatureFlag` are watching this parameter
             * to unserstand if the user authorisation has initiated or not.
             * We do not care about the result but rather there is no pending
             * request.
             *
             */
            setState({ user: user ?? undefined, isLoading: false })
            fn?.()
        })

        return observer
    }, [fn])

    return {
        ...state,
        isLoggedIn: !!state.user && !!getLocalUserData()?.accessToken,
    }
}
