import { doc, onSnapshot } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { db, initZimBackendApp } from '../../firebase'
import { useUser } from '../../firebase/useUser'
import { getLocalUserData, setWarehouse } from '../../utils/dataStore'
import { COUNTRY } from '../../utils/env'
import { useUserDetails } from './_utils/getUserDetails'
import { useWarehouseDetails } from './_utils/getWarehouseDetails'
import { defaultAppState } from './context'
import { AppContextInterface } from './types'

const stateDeltas = (
    state: AppContextInterface,
    newState: Partial<AppContextInterface>
): AppContextInterface => ({
    ...state,
    ...newState,
})

export const useAppProvider = () => {
    /* Sync call, there is no side effect here */
    const userCountry = COUNTRY.toLocaleLowerCase()

    /**
     * Initiate State with default values
     */
    const [state, dispatch] = useReducer(stateDeltas, { ...defaultAppState })

    const [isZimBackendInitiated, setIsZimBackendInitiated] = useState(false)

    /**
     * Side Effects
     */
    const {
        user = null,
        isLoggedIn: isUserLoggedIn,
        isLoading: isUserLoading,
    } = useUser()
    const [userDetails] = useUserDetails(user?.uid, userCountry)
    const [warehouse] = useWarehouseDetails(userDetails?.warehouseId)

    /**
     * Callbacks
     */
    const subscribeCbk = useCallback(() => {
        return onSnapshot(
            doc(db, 'countries', COUNTRY.toLowerCase()),
            (snapshot) => {
                dispatch({
                    isZimZippInMaintenance:
                        !!snapshot.data()?.isZimZippInMaintenance,
                })
            }
        )
    }, [])

    const logoutUser = useCallback(() => {
        dispatch({ ...defaultAppState })
    }, [])

    /**
     * Use Effects
     */
    useEffect(() => {
        ;(async (): Promise<void> => {
            await initZimBackendApp()
            setIsZimBackendInitiated(true)
        })()
    }, [])

    useEffect(() => {
        const subscriber = subscribeCbk()

        return subscriber
    }, [subscribeCbk])

    /* Set a fresh copy of the whole state */
    useEffect(() => {
        // ? Sync it with LocalStorage
        if (warehouse?.id) setWarehouse(warehouse)

        dispatch({
            isUserLoggedIn,
            isUserLoading,
            isAppLoaded: Boolean(
                userDetails?.warehouseId &&
                    warehouse?.id &&
                    isZimBackendInitiated
            ),
            user,
            userAccessToken: getLocalUserData()?.accessToken ?? '',
            userDetails,
            warehouse,
            warehouseId: userDetails?.warehouseId ?? '',
        })
    }, [
        isZimBackendInitiated,
        isUserLoading,
        userDetails,
        warehouse,
        isUserLoggedIn,
        user,
    ])

    /**
     * memoized app state
     */
    const contextValue = useMemo<AppContextInterface>(
        () => ({
            ...state,
            logoutUser,
        }),

        /**
         * * TO NOTE:
         * We need to make AN EXCEPTION and disable the 'exhaustive-deps'
         * eslint rule due to performance reasons.
         * In this specific scenario we do not need to watch for every change
         * that happens in the state. But rather,
         * watch ONLY the changes that denotes when application
         * is ready to render or not.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.isAppLoaded, state.isUserLoading, state.isUserLoggedIn]
    )
    // console.log(++cnt, contextValue)
    return contextValue
}
