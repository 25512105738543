import { createOvermind } from 'overmind'
import { Provider as OvermindProvider } from 'overmind-react'
import React from 'react'
import { Provider as FeatureFlagProvider } from '../../Containers/FeatureFlag'
import { DeviceProvider } from '../../store/devices/DeviceProvider'
import { OrdersProvider } from '../../store/orders/OrdersProvider'
import { config } from '../../store/overmind/OrdersOvermind'
import ToastProvider from '../../store/toast/ToastProvider'
import { Theme } from '../Theme'

const overmind = createOvermind(config, {
    logProxies: false,
})

/**
 * CommonProviders is used to setup all Providers that we need.
 * Additonal providers like ApolloProvider shoul be added here.
 */
const CommonProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <Theme>
            <FeatureFlagProvider>
                <ToastProvider>
                    <OrdersProvider>
                        <OvermindProvider value={overmind}>
                            <DeviceProvider>{children}</DeviceProvider>
                        </OvermindProvider>
                    </OrdersProvider>
                </ToastProvider>
            </FeatureFlagProvider>
        </Theme>
    )
}

export default CommonProviders
