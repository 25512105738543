// TODO Move this to pipeline or config
import { Country } from '../types/country'
import { COUNTRY } from '../utils/env'

// config development
const firebaseConfigDevelopment = {
    apiKey: 'AIzaSyDbFBBBE3UOZs15O0vbkRprGGj7Zy4XcfY',
    authDomain: 'zapp-development.firebaseapp.com',
    projectId: 'zapp-development',
    storageBucket: 'zapp-development.appspot.com',
    messagingSenderId: '218525758546',
    appId: '1:218525758546:web:c8d908d70e1bc0840b1b8b',
}

// config production
const firebaseConfigProduction = {
    apiKey: 'AIzaSyAoDWn9p0Sx_CEM3bCXotYZmfcMauTWMYA',
    authDomain: 'project-corner.firebaseapp.com',
    databaseURL: 'https://project-corner.firebaseio.com',
    projectId: 'project-corner',
    storageBucket: 'project-corner.appspot.com',
    messagingSenderId: '66218108484',
    appId: '1:66218108484:web:2cf6f37b3f9877d705be11',
    measurementId: 'G-GWSVQCE82X',
}

const zimBackendDevelopmentConfig = {
    appId: '1:1048113695422:web:57764906814b8e3a2dacef',
    projectId: 'zapp-dev-app-zim-420b',
    apiKey: 'AIzaSyD7NdO_ze0TB9ZKbvokau5BSje7znzYGko',
    measurementId: 'G-86BE3VPCWK',
}

const zimBackendStagingConfig = {
    appId: '1:142049770756:web:d2554e3ac482b6486f2451',
    projectId: 'zapp-stg-app-zim-2f01',
    apiKey: 'AIzaSyB4t5MYqm8D-okZaC3a3xzj0NpRYGGQ8Gw',
    measurementId: 'G-CX289MDETE',
}

const zimBackendGBConfig = {
    appId: '1:210927783896:web:c58e1d832d7682698a6cad',
    projectId: 'zapp-gb-app-zim-8c13',
    apiKey: 'AIzaSyA9lvxFF3g-whPLx-nPIXxbklPv-pgbx7A',
    measurementId: 'G-QHQS6JPYJ0',
}

const zimBackendNLConfig = {
    appId: '1:781654451426:web:bcbae0d40bbc1762096abb',
    projectId: 'zapp-nl-app-zim-b3ea',
    apiKey: 'AIzaSyBeS7-uTv_aOrbenXd-PfKymqZeYfu_-mg',
    measurementId: 'G-KPZQNDW7HP',
}

const zimBackendFRConfig = {
    appId: '1:712249621486:web:9d557e19f9682dc52d6cff',
    projectId: 'zapp-fr-app-zim-c4e4',
    apiKey: 'AIzaSyAeF4QGPS-Kc-UNc131fMJ5QMewrH5qn88',
    measurementId: 'G-HYX90PL2V6',
}

export const firebaseConfig =
    process.env.REACT_APP_FIREBASE_CONFIG === 'production'
        ? firebaseConfigProduction
        : firebaseConfigDevelopment

export const getZimBackendConfig = () => {
    if (
        process.env.REACT_APP_FIREBASE_CONFIG === 'staging' ||
        process.env.REACT_APP_FIREBASE_CONFIG === 'localhost'
    ) {
        return zimBackendStagingConfig
    }

    if (process.env.REACT_APP_FIREBASE_CONFIG === 'development') {
        return zimBackendDevelopmentConfig
    }

    switch (COUNTRY) {
        case Country.GB:
            return zimBackendGBConfig
        case Country.NL:
            return zimBackendNLConfig
        case Country.FR:
            return zimBackendFRConfig
    }
}

export const getZimFunctionsRegion = () => {
    if (process.env.REACT_APP_FIREBASE_CONFIG === 'localhost') {
        return 'us-central1'
    }

    if (process.env.REACT_APP_FIREBASE_CONFIG !== 'production') {
        return 'europe-west2'
    }

    switch (COUNTRY) {
        case Country.GB:
            return 'europe-west2'
        case Country.NL:
        case Country.FR:
            return 'europe-west1'
    }
}
