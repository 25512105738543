import { createContext } from 'react'
import { AppContextInterface } from './types'

/**
 * @ignore
 */
export const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AppStateProvider>.')
}

export const defaultAppState: AppContextInterface = {
    isZimZippInMaintenance: false,
    isUserLoggedIn: false,
    isUserLoading: false,
    isAppLoaded: false,
    user: null,
    userAccessToken: '',
    userDetails: null,
    warehouse: null,
    warehouseId: '',
    /* Callbacks */
    logoutUser: stub,
}

export const AppStateContext = createContext<AppContextInterface | null>(null)
