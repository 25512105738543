import { Device } from '@quickcommerceltd/zipp'
import { doc } from 'firebase/firestore'
import React, { useCallback, useEffect } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useAppState } from '../../Containers/AppState'
import { useFeatureFlag } from '../../Containers/FeatureFlag'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import dataStore, { getLocalUserData } from '../../utils/dataStore'
import { setDeviceStatus } from '../../utils/deviceStatus'
import { log } from '../../utils/logHelper'

export const DeviceProvider: React.FC = ({ children }) => {
    const {
        values: { deviceHeartbeatIntervalSeconds },
    } = useFeatureFlag(['deviceHeartbeatIntervalSeconds'])

    const { user, userDetails } = useAppState()

    const updateDeviceStatus = useCallback(async () => {
        const localUserData = getLocalUserData()
        const isOnline = !!localUserData?.accessToken
        const pushToken = window.pushToken // injected by the expo wrapper. undefined in web browser
        await setDeviceStatus({
            isOnline,
            pushToken,
            userId: user?.uid,
            userEmail: userDetails?.email,
            userName: userDetails?.name,
            warehouseId: userDetails?.warehouseId,
        })
    }, [
        user?.uid,
        userDetails?.email,
        userDetails?.name,
        userDetails?.warehouseId,
    ])

    // update device status using a heartbeat
    useEffect(() => {
        updateDeviceStatus()
        const INTERVAL =
            ((deviceHeartbeatIntervalSeconds as number) || 30) * 1000
        const heartbeat = setInterval(updateDeviceStatus, INTERVAL)
        return () => clearInterval(heartbeat)
    }, [deviceHeartbeatIntervalSeconds, updateDeviceStatus])

    return <>{children}</>
}

const deviceConverter = new GenericConverter<Device>()
export function useDevice() {
    const deviceId = dataStore.getDeviceId()
    const deviceState = useDocumentData<Device>(
        doc(zimBackendDb, `devices/${deviceId}`).withConverter(deviceConverter)
    )
    const [, , error] = deviceState
    useEffect(() => {
        if (error) {
            log('device', 'error', {
                error,
                deviceId,
            })
        }
    }, [deviceId, error])

    return deviceState
}
