import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    WithFieldValue,
} from 'firebase/firestore'

export class GenericConverter<T> implements FirestoreDataConverter<T> {
    toFirestore(object: WithFieldValue<T>): DocumentData {
        return object as any
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            exists: snapshot.exists(),
            ...(snapshot.data(options) as T),
        }
    }
}
