import { FirestoreCollection } from '@quickcommerceltd/zapp-app-utils/build/types/FirestoreCollection'
import { COUNTRY } from '../../utils/env'

export const getVariantDownloadListCollection = () => {
    return (
        COUNTRY.toLowerCase() + '_' + FirestoreCollection.VARIANT_DOWNLOAD_LIST
    )
}

export const getStockAdjustmentsCollection = () => {
    return COUNTRY.toLowerCase() + '_' + FirestoreCollection.STOCK_ADJUSTMENTS
}

export const getUsersCollection = () => {
    return COUNTRY.toLowerCase() + '_' + FirestoreCollection.USERS
}

export const getPurchaseOrdersCollection = () => {
    return COUNTRY.toLowerCase() + '_' + FirestoreCollection.PURCHASE_ORDERS
}

export const getStockHistoryCollection = () => {
    return COUNTRY.toLowerCase() + '_stockHistory'
}

export const getSkuLocationCollection = () => {
    return COUNTRY.toLowerCase() + '_skuLocations'
}

export const getEventsCollection = () => {
    return COUNTRY.toLowerCase() + '_events'
}

export const getExpiryDateCollection = () => {
    return COUNTRY.toLowerCase() + '_expiryDates'
}
