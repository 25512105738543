import { doc, DocumentReference } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../../firebase'
import { GenericConverter } from '../../../firebase/genericConverter'
import { AppContextInterface } from '../types'

type Warehouse = AppContextInterface['warehouse']

const converter = new GenericConverter<any>()

export const useWarehouseDetails = (
    warehouseId: string | undefined
): [Warehouse | null] => {
    const query: DocumentReference<Warehouse> = doc(
        db,
        `warehouseConfigs/${warehouseId}`
    ).withConverter(converter)

    const [warehouse = null] = useDocumentData(warehouseId ? query : undefined)

    return [warehouse]
}
