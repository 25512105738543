import {
    createContext,
    memo,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react'
import ZSnack, { AlertSeverity } from '../../Components/ZSnack/ZSnack'

export interface ToastParams {
    message: string
    severity?: AlertSeverity
    autoHideDuration?: number
}

export interface ToastActions {
    show: (params: ToastParams) => void
    hide: () => void
}

const defaultActons = { show: () => {}, hide: () => {} }

const defaultValue = {
    show: false,
    message: '',
    severity: AlertSeverity.SUCCESS,
    autoHideDuration: 5000,
}

const ToastContext = createContext<ToastActions>(defaultActons)

export const useToast = () => useContext(ToastContext)

const ToastProvider = memo(({ children }) => {
    const [visible, setVisible] = useState(false)
    const [state, setState] = useState<ToastParams>(defaultValue)
    const show = useCallback((params: ToastParams) => {
        setState(params)
        setVisible(true)
    }, [])
    const hide = useCallback(() => setVisible(false), [])
    const toast = useMemo(() => ({ show, hide }), [hide, show])
    return (
        <ToastContext.Provider value={toast}>
            {children}
            <ZSnack
                show={visible}
                onClose={hide}
                message={state.message}
                severity={state.severity}
                autoHideDuration={state.autoHideDuration}
            />
        </ToastContext.Provider>
    )
})

export default ToastProvider
