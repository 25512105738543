import React from 'react'
import { AppStateContext } from './context'
import { useAppProvider } from './useProvider'

export const AppStateProvider: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    const appState = useAppProvider()

    return (
        <AppStateContext.Provider value={appState}>
            {children}
        </AppStateContext.Provider>
    )
}
