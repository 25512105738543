import { lazy } from 'react'
import { BrowserRouter, MemoryRouter, Redirect } from 'react-router-dom'

import { ROUTES } from './consts/Routes'
import Login from './Containers/Login/Login'
import OfflinePage from './Containers/OfflinePage'

const SignupComplete = lazy(() => import('./Containers/SignUp/SignUpComplete'))

const DashboardPage = lazy(() => import('./Containers/Dashboard/Dashboard'))

const Putaway = lazy(() => import('./Containers/Putaway/Putaway'))
const PutawayList = lazy(
    () => import('./Containers/Putaway/PutawayList/PutawayList')
)

const PutawaySuccess = lazy(
    () => import('./Containers/Putaway/PutawaySuccess/PutawaySuccess')
)
const PutawayItem = lazy(
    () => import('./Containers/Putaway/PutawayItem/PutawayItem')
)

const Profile = lazy(() => import('./Containers/Profile/Profile'))
const WarehouseSecurity = lazy(
    () => import('./Containers/WarehouseSelect/WarehouseSecurity')
)
const WarehouseSelect = lazy(
    () => import('./Containers/WarehouseSelect/WarehouseSelect')
)

const InboundPOSearch = lazy(() => import('./Containers/InboundPO/InboundPO'))
const InboundPOItems = lazy(
    () => import('./Containers/InboundPO/InboundPOItems/InboundPOItems')
)
const InboundV2 = lazy(() => import('./Containers/InboundV2'))
const InboundV2PurchaseOrder = lazy(
    () => import('./Containers/InboundV2/InboundPurchaseOrder')
)
const InboundV2Item = lazy(() => import('./Containers/InboundV2/InboundItem'))
const InboundPOInput = lazy(
    () => import('./Containers/InboundProducts/InboundPOInput')
)
const CycleCount = lazy(() => import('./Containers/CycleCount/CycleCount'))

const CycleCountV2 = lazy(() => import('./Containers/CycleCountV2/CycleCount'))

const CycleCountV2Details = lazy(
    () => import('./Containers/CycleCountV2/CycleCountDetails')
)

const AssignSKULocation = lazy(
    () => import('./Containers/AssignSKULocation/AssignSKULocation')
)
const QueueListener = lazy(() => import('./Containers/QueueListener'))
const Task = lazy(() => import('./Containers/Task'))
const Orders = lazy(() => import('./Containers/Orders/Orders'))

const Picking = lazy(() => import('./Containers/Picking/Picking'))

const SkuLocations = lazy(
    () => import('./Containers/SkuLocations/SkuLocations')
)

const AdjustStockV2 = lazy(
    () => import('./Containers/StockAdjustmentV2/AdjustStock')
)
const MoveSKUV2 = lazy(() => import('./Containers/StockAdjustmentV2/MoveSKU'))

const PickingV2 = lazy(() => import('./Containers/PickingV2'))
const PickingV2ProductList = lazy(
    () => import('./Containers/PickingV2/PickingProductList')
)
const PickingV2Success = lazy(
    () => import('./Containers/PickingV2/PickingSuccess')
)

const StockMovementExplorer = lazy(
    () => import('./Containers/StockMovementExplorer/StockMovementExplorer')
)

const ExpirySweeper = lazy(
    () => import('./Containers/ExpirySweeper/ExpirySweeper')
)

const DiscrepancySweeper = lazy(
    () => import('./Containers/DiscrepancySweeper/DiscrepancySweeper')
)

const DiscrepancySweeperDetails = lazy(
    () => import('./Containers/DiscrepancySweeper/DiscrepancySweeperDetails')
)
const Debugging = lazy(() => import('./Containers/Debugging'))

const audio = new Audio(
    'https://firebasestorage.googleapis.com/v0/b/project-corner.appspot.com/o/zoee-new-order-sound.mp3?alt=media&token=e1727c9d-5c0c-4a2f-bd4a-14c0c84a39db'
)

const AutoInboundProducts = lazy(
    () =>
        import(
            './Containers/AutoInbound/AutoInboundProducts/AutoInboundProducts'
        )
)

audio.loop = true

/**
 * Switch between
 * Browser and MemoryRouter
 *
 */
export const ZimRouter: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    let Component = BrowserRouter
    if (process.env.NODE_ENV === 'test') {
        Component = MemoryRouter
    }

    return (
        <Component>
            <>{children}</>
        </Component>
    )
}

export const INDEX_ROUTE = {
    path: ROUTES.INDEX,
    render: () => {
        // useMediaQuery returns wrong value at first render!
        const smallScreen = window.innerWidth <= 800
        return smallScreen ? (
            <Redirect to={ROUTES.QUEUE_LISTENING} />
        ) : (
            <Redirect to={ROUTES.PURCHASE_ORDERS} />
        )
    },
}

export const PUBLIC_ROUTES = [
    {
        path: ROUTES.ERROR,
        component: OfflinePage,
    },
    {
        path: ROUTES.LOGIN,
        component: Login,
    },
    {
        path: ROUTES.SIGNUP_COMPLETE,
        component: SignupComplete,
    },
    {
        path: ROUTES.WAREHOUSE_SELECTION,
        component: WarehouseSelect,
    },
]

export const PRIVATE_ROUTES = [
    {
        path: ROUTES.PROFILE,
        component: Profile,
    },
    {
        path: ROUTES.SKU_ANALYSIS,
        component: DashboardPage,
    },
    /* FOR ZOEE WEB APP */
    {
        path: ROUTES.DASHBOARD,
        component: DashboardPage,
    },
    {
        path: ROUTES.UPDATE_PRODUCT_LIST,
        component: DashboardPage,
    },
    {
        path: ROUTES.UPDATE_PRODUCT_LIST_TABLE,
        component: DashboardPage,
    },
    {
        path: ROUTES.PURCHASE_ORDERS,
        component: DashboardPage,
    },
    {
        path: ROUTES.PURCHASE_ORDER_DETAIL,
        component: DashboardPage,
    },
    {
        path: ROUTES.WAREHOUSE_LOCATIONS,
        component: DashboardPage,
    },
    {
        path: ROUTES.STOCK_ADJUSTMENT,
        component: DashboardPage,
    },
    {
        path: ROUTES.STOCK_ADJUSTMENT_V2_ADJUST_STOCK,
        component: AdjustStockV2,
    },
    {
        path: ROUTES.STOCK_ADJUSTMENT_V2_MOVE_SKU,
        component: MoveSKUV2,
    },
    {
        path: ROUTES.STOCK_ADJUSTMENT_V2_MOVE_SKU_REPLENISHMENT,
        component: MoveSKUV2,
        exact: false,
    },
    {
        path: ROUTES.USERS,
        component: DashboardPage,
    },
    {
        path: ROUTES.LOCATIONS_V2,
        component: DashboardPage,
    },
    {
        path: ROUTES.LOCATIONS_V3,
        component: DashboardPage,
    },
    {
        path: ROUTES.STOCK_CHECKS,
        component: DashboardPage,
    },
    /* FOR PICKER APP */
    {
        path: ROUTES.ORDERS,
        component: Orders,
    },
    {
        path: ROUTES.QUEUE_LISTENING,
        component: () => <QueueListener audio={audio} />,
    },
    {
        path: ROUTES.TASK_PERMALINK,
        component: () => <Task />,
    },
    {
        path: ROUTES.PICKLIST,
        component: Orders,
    },
    {
        path: ROUTES.WAREHOUSE_SECURITY,
        component: WarehouseSecurity,
    },
    {
        path: ROUTES.INBOUND_PO_NO,
        component: InboundPOInput,
    },
    {
        path: ROUTES.PICKING,
        component: Picking,
    },
    {
        path: ROUTES.INBOUND_PO_SEARCH,
        component: InboundPOSearch,
    },
    {
        path: ROUTES.INBOUND_PO_ITEMS,
        component: InboundPOItems,
    },
    {
        path: ROUTES.INBOUND_V2,
        component: InboundV2,
    },
    {
        path: ROUTES.INBOUND_V2_PURCHASE_ORDER,
        component: InboundV2PurchaseOrder,
    },
    {
        path: ROUTES.INBOUND_V2_ITEM,
        component: InboundV2Item,
    },
    {
        path: ROUTES.PUTAWAY,
        component: Putaway,
    },
    {
        path: ROUTES.PUTAWAY_SUCCESS,
        component: PutawaySuccess,
    },
    {
        path: ROUTES.PUTAWAY_LIST,
        component: PutawayList,
    },
    {
        path: ROUTES.PUTAWAY_ITEM,
        component: PutawayItem,
    },
    {
        path: ROUTES.PO_SKU_LOCATIONS,
        component: SkuLocations,
    },
    {
        path: ROUTES.PICKING_V2,
        component: PickingV2,
    },
    {
        path: ROUTES.PICKING_V2_PRODUCT_LIST,
        component: PickingV2ProductList,
    },
    {
        path: ROUTES.PICKING_V2_SUCCESS,
        component: PickingV2Success,
    },
    {
        path: ROUTES.STOCK_MOVEMENT_EXPLORER,
        component: StockMovementExplorer,
    },
    {
        path: ROUTES.STOCK_MOVEMENT_EXPLORER_DETAILS,
        component: StockMovementExplorer,
    },
    {
        path: ROUTES.DISCREPANCY_SWEEPER,
        component: DiscrepancySweeper,
    },
    {
        path: ROUTES.DISCREPANCY_SWEEPER_DETAILS,
        component: DiscrepancySweeperDetails,
    },
    {
        path: ROUTES.EXPIRY_SWEEPER,
        component: ExpirySweeper,
    },
    {
        path: ROUTES.PO_SKU_LOCATIONS_SEARCH,
        component: SkuLocations,
    },
    {
        path: ROUTES.CYCLE_COUNT,
        component: CycleCount,
    },
    {
        path: ROUTES.CYCLE_COUNT_V2,
        component: CycleCountV2,
    },
    {
        path: ROUTES.CYCLE_COUNT_V2_DETAILS,
        component: CycleCountV2Details,
    },
    {
        path: ROUTES.ASSIGN_SKU_LOCATION,
        component: AssignSKULocation,
    },
    {
        path: ROUTES.DEBUGGING,
        component: Debugging,
    },
    {
        path: ROUTES.AUTO_INBOUND_PRODUCTS,
        component: AutoInboundProducts,
    },
]
