import type { WarehouseConfig } from '@quickcommerceltd/zapp-app-utils'
import { WarehouseType } from '../types'
import { getWarehouse } from './dataStore'

/**
 * TODO: Remove `isDCFallback` when production data gets updated.
 *
 * To note, update external code that is using this method
 *
 */

export const isDCFallback = (warehouseId?: string): boolean => {
    const id = warehouseId || getWarehouse().id

    return [
        'uk_london_parkroyal-distribution',
        'nl_amsterdam_houthavens-overstock',
    ].includes(id)
}

export const getDCWarehouseId = () => {
    return 'uk_london_parkroyal-distribution'
}

export const getDCShortName = (): string => {
    if (isDCWarehouse()) {
        const warehouse = getWarehouse()
        return warehouse.shortName
    }

    return ''
}

export const isDCWarehouse = (warehouse?: any): boolean => {
    const { type } = warehouse || getWarehouse()

    return !type ? isDCFallback() : type === WarehouseType.DISTRIBUTION
}

export const attachTypeIntoWarehouse = (
    warehouse: WarehouseConfig
): WarehouseConfig => {
    const type = warehouse.type
        ? warehouse.type
        : isDCFallback(warehouse.id)
        ? WarehouseType.DISTRIBUTION
        : WarehouseType.STORE

    return { ...warehouse, type }
}
