export const QRCode = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="116"
            height="116"
            viewBox="0 0 2560 2560"
        >
            <g>
                <g>
                    <path d="M385 2371 c-50 -13 -111 -48 -139 -82 -60 -71 -61 -75 -61 -422 l0 -319 24 -19 c30 -24 82 -24 112 0 l24 19 3 304 3 303 26 27 26 27 304 3 305 3 19 24 c24 30 24 82 0 112 l-19 24 -299 2 c-164 1 -311 -2 -328 -6z"></path>
                    <path d="M1553 2370 c-45 -18 -58 -90 -24 -131 l19 -24 304 -3 303 -3 27 -26 27 -26 3 -304 3 -305 24 -19 c30 -24 82 -24 112 0 l24 19 0 319 0 318 -27 51 c-30 56 -71 96 -127 122 -31 14 -80 17 -341 19 -168 1 -315 -2 -327 -7z"></path>
                    <path d="M557 2002 c-15 -16 -17 -54 -17 -325 0 -294 1 -308 20 -327 19 -19 33 -20 323 -20 166 0 308 4 316 9 8 5 10 14 6 21 -5 9 -4 11 3 6 17 -10 17 625 0 642 -9 9 -95 12 -323 12 -289 0 -313 -1 -328 -18z m543 -327 l0 -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0 -225z"></path>
                    <path d="M800 1678 l0 -83 81 2 81 1 1 81 2 81 -82 0 -83 0 0 -82z"></path>
                    <path d="M1371 1901 l-2 -80 86 -3 85 -3 0 83 0 82 -84 0 -83 0 -2 -79z"></path>
                    <path d="M1818 1901 l3 -80 80 -3 79 -3 0 83 0 82 -82 0 -83 0 3 -79z"></path>
                    <path d="M1597 1679 l1 -81 81 -1 81 -2 0 83 0 82 -82 0 -83 0 2 -81z"></path>
                    <path d="M1371 1455 l-2 -85 85 0 86 0 0 85 0 85 -84 0 -83 0 -2 -85z"></path>
                    <path d="M1818 1455 l3 -85 80 0 79 0 0 85 0 85 -82 0 -83 0 3 -85z"></path>
                    <path d="M560 1210 c-19 -19 -20 -33 -20 -328 0 -287 1 -310 18 -325 16 -15 54 -17 328 -17 228 0 313 3 322 12 17 17 17 652 0 642 -7 -5 -8 -3 -3 6 4 7 2 16 -6 21 -8 5 -150 9 -316 9 -290 0 -304 -1 -323 -20z m540 -325 l0 -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0 -225z"></path>
                    <path d="M800 883 l0 -83 83 0 82 0 -2 81 -1 81 -81 1 -81 2 0 -82z"></path>
                    <path d="M1361 1221 c-8 -5 -10 -14 -6 -21 5 -9 4 -11 -3 -6 -17 10 -17 -625 0 -642 9 -9 95 -12 323 -12 289 0 313 1 328 18 15 16 17 54 17 325 0 294 -1 308 -20 327 -19 19 -33 20 -322 20 -167 0 -309 -4 -317 -9z m539 -336 l0 -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0 -225z"></path>
                    <path d="M1600 965 c-1 0 -2 -37 -3 -83 l-2 -82 83 0 82 0 0 83 0 82 -80 0 c-44 0 -80 0 -80 0z"></path>
                    <path d="M209 1031 l-24 -19 0 -319 c0 -279 2 -322 17 -354 27 -57 66 -98 122 -127 l51 -27 318 0 319 0 19 24 c24 30 24 82 0 112 l-19 24 -305 3 -304 3 -26 27 -26 27 -3 303 -3 304 -24 19 c-30 24 -82 24 -112 0z"></path>
                    <path d="M2239 1031 l-24 -19 -3 -304 -3 -303 -26 -27 -26 -27 -304 -3 -305 -3 -19 -24 c-24 -30 -24 -82 0 -112 l19 -24 319 0 c279 0 322 2 354 17 57 27 98 66 127 122 l27 51 0 318 0 319 -24 19 c-30 24 -82 24 -112 0z"></path>
                </g>
            </g>
        </svg>
    )
}
