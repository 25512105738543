import {
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    makeStyles,
} from '@material-ui/core'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

interface MenuLinkProps {
    icon: JSX.Element
    badge?: boolean | JSX.Element
    to?: string
    onClick?: () => void
}

const useStyles = makeStyles(() => ({
    root: {
        fontSize: 18,
        fontWeight: 500,
        cursor: 'pointer',
        color: '#0B65CF',
        paddingTop: 12,
        paddingBottom: 12,
        '&:hover': { '& $text': { color: 'inherit' } },
        borderRadius: 0,
    },
    icon: { color: 'inherit', minWidth: 'auto', marginRight: 15 },
    text: { fontSize: 18, color: '#444' },
    badge: { pointerEvents: 'none' },
}))

const MenuItem: React.FC<MenuLinkProps> = ({
    icon,
    children,
    badge,
    to,
    onClick,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        }
        if (to) {
            history.push(to)
        }
    }, [history, onClick, to])

    return (
        <ListItem
            button
            onClick={handleClick}
            className={classes.root}
            color="initial"
        >
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <span className={classes.text}>{children}</span>
            {badge && (
                <ListItemSecondaryAction className={classes.badge}>
                    {badge}
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}

export default MenuItem
