/* eslint-disable import/order */
import { Analytics, getAnalytics } from 'firebase/analytics'
import { Auth, getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import {
    connectFunctionsEmulator,
    Functions,
    getFunctions,
} from 'firebase/functions'
import { RemoteConfig, getRemoteConfig } from 'firebase/remote-config'
import { getStorage } from 'firebase/storage'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

import {
    firebaseConfig,
    getZimBackendConfig,
    getZimFunctionsRegion,
} from './firebaseConfig'

let sharedBackend: firebase.app.App
let zimBackend: firebase.app.App
let zimAnalytics: Analytics
let zimFunctions: Functions
let zimBackendRemoteConfig: RemoteConfig
let zimBackendDb: firebase.firestore.Firestore
let zimBackendAuth: Auth

if (firebase.apps.length === 0) {
    sharedBackend = firebase.initializeApp(firebaseConfig)
    // firebase.functions().useEmulator("localhost", 5001)
} else {
    sharedBackend = firebase.app()
}

export const initZimBackendApp = async () => {
    if (zimBackend) {
        await firebase.app('zimBackend').delete()
    }
    const config = getZimBackendConfig()
    zimBackend = firebase.initializeApp(config, 'zimBackend')

    zimAnalytics = getAnalytics(zimBackend)

    const functionsRegion = getZimFunctionsRegion()
    zimFunctions = getFunctions(zimBackend, functionsRegion)
    if (process.env.REACT_APP_FIREBASE_CONFIG === 'localhost') {
        connectFunctionsEmulator(zimFunctions, 'localhost', 5001)
    }
    zimBackendDb = zimBackend.firestore()
    zimBackendDb.settings({ ignoreUndefinedProperties: true, merge: true })

    zimBackendAuth = getAuth(zimBackend)

    zimBackendRemoteConfig = getRemoteConfig(zimBackend)
    zimBackendRemoteConfig.settings.minimumFetchIntervalMillis = 600_000 // 10 minutes
}

const auth = getAuth(sharedBackend)
const db = initializeFirestore(sharedBackend, {
    ignoreUndefinedProperties: true,
})
const sharedStorage = getStorage(sharedBackend)

export {
    firebase,
    zimAnalytics,
    zimBackendDb,
    zimFunctions,
    zimBackendRemoteConfig,
    zimBackendAuth,
    db,
    auth,
    sharedStorage,
}
