import { addDoc, collection } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { APP_VERSION } from '../consts/version'
import { getEventsCollection } from '../Containers/CountryProvider/CountryProvider'
import { db, zimFunctions } from '../firebase'
import { getLocalUserData, LocalUserData } from './dataStore'

const getPayload = (
    category: string,
    action: string,
    value: any,
    userData: Partial<LocalUserData> = {}
) => {
    if (value.error instanceof Error) {
        value.error = value.error.message
    }
    return {
        category,
        action,
        value: value instanceof Error ? value.message : value,
        warehouseId: userData.userDetails?.warehouseId,
        error: value instanceof Error ? value.message : value.error,
        email: userData.email,
        referenceId: value.id || null,
        sku: value?.sku || null,
        barcode: value?.barcode || null,
        userData,
        version: APP_VERSION,
        moreInfo: {
            date: new Date(),
            navigator: {
                appCodeName: navigator.appCodeName,
                appName: navigator.appName,
                appVersion: navigator.appVersion,
                cookieEnabled: navigator.cookieEnabled,
                language: navigator.language,
                userAgent: navigator.userAgent,
                platform: navigator.platform,
                onLine: navigator.onLine,
            },
        },
        createdAt: new Date(),
    }
}

const logToFirestore = (payload: ReturnType<typeof getPayload>) => {
    const path = getEventsCollection()
    addDoc(collection(db, path), payload).catch(() => {})
}
const logToCloudLogging = (payload: ReturnType<typeof getPayload>) => {
    const log = httpsCallable(zimFunctions, 'log')
    log(payload).catch(() => {})
}

export const log = (category: string, action: string, value: any) => {
    const userData: Partial<LocalUserData> | undefined = getLocalUserData()
    if (userData?.accessToken) {
        delete userData.accessToken
    }
    const payload = getPayload(category, action, value, userData)

    logToFirestore(payload)
    logToCloudLogging(payload)
}
