import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { REQUEST_STATUS } from '../../consts/requestStatus'
import { ROUTES } from '../../consts/Routes'
import { useAppState } from '../../Containers/AppState'
import FullScreenSpinner from '../FullScreenSpinner'

const CheckLogin = ({ children }: { children: React.ReactNode }) => {
    const { isAppLoaded, isUserLoading, user } = useAppState()
    const history = useHistory()

    useEffect(() => {
        if (!user?.uid && !isUserLoading) {
            localStorage.clear()
            sessionStorage.clear()
            history.replace(ROUTES.LOGIN)
        }
    }, [history, isUserLoading, user])

    return (
        <FullScreenSpinner
            requestStatus={
                !isAppLoaded || isUserLoading
                    ? REQUEST_STATUS.PENDING
                    : REQUEST_STATUS.FULFILLED
            }
            message="Authorizing...."
            onTryAgain={() => {}}
        >
            {children}
        </FullScreenSpinner>
    )
}

export default CheckLogin

/**
 * HOC for easy usage.
 *
 * withCheckLogin(SomeRouteComponent)
 */
export const withCheckLogin =
    (Component: React.ComponentType): React.VFC =>
    (props) =>
        (
            <CheckLogin>
                <Component {...props} />
            </CheckLogin>
        )
