import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { memo } from 'react'

export enum AlertSeverity {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

interface IProps {
    show: boolean
    message: string
    autoHideDuration?: number
    onClose: () => void
    severity?: AlertSeverity
    style?: object
}

export interface ISnack {
    show: boolean
    message: string
    severity?: AlertSeverity
}

const Alert = memo((props: AlertProps) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
))

const ZSnack = memo((props: IProps) => (
    <Snackbar
        open={props.show}
        autoHideDuration={props.autoHideDuration || 5000}
        onClose={props.onClose}
        style={props.style}
        ClickAwayListenerProps={{
            mouseEvent: false,
            touchEvent: false,
        }}
    >
        <Alert
            onClose={props.onClose}
            severity={props.severity || AlertSeverity.SUCCESS}
        >
            {props.message}
        </Alert>
    </Snackbar>
))

export default ZSnack
