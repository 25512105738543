import { Hidden } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { withCheckLogin } from './Components/CheckLogin/CheckLogin'

import CommonProviders from './Components/CommonProviders/CommonProviders'
import InternetCheck from './Components/InternetCheck/InternetCheck'
import SideDrawer from './Components/SideDrawer/SideDrawer'
import { StatusBar } from './Components/StatusBar/StatusBar'

import { useAppState } from './Containers/AppState'
import Maintenance from './Containers/Maintenance/Maintenance'
import ErrorBoundary from './ErrorBoundary'
import { INDEX_ROUTE, PRIVATE_ROUTES, PUBLIC_ROUTES, ZimRouter } from './Router'

function App() {
    const {
        userDetails = null,
        warehouse = null,
        isZimZippInMaintenance,
    } = useAppState()

    if (isZimZippInMaintenance) {
        return <Maintenance />
    }

    return (
        <ErrorBoundary>
            <CommonProviders>
                <Helmet>
                    <title>ZIM</title>
                </Helmet>
                <ZimRouter>
                    <Switch>
                        <Route
                            exact
                            path={INDEX_ROUTE.path}
                            render={INDEX_ROUTE.render}
                        />

                        {PUBLIC_ROUTES.map(({ path, component }, idx) => (
                            <Route
                                key={idx}
                                exact
                                path={path}
                                component={component}
                            />
                        ))}
                    </Switch>

                    {/* AUTHORIZED/PRIVATE ACCESS */}
                    <StatusBar
                        email={userDetails?.email || ''}
                        shortName={warehouse?.shortName || ''}
                    />

                    <Hidden smUp>
                        <SideDrawer />
                    </Hidden>

                    <Switch>
                        {PRIVATE_ROUTES.map(
                            ({ path, component, exact = true }, idx) => (
                                <Route
                                    key={idx}
                                    exact={exact}
                                    path={path}
                                    component={withCheckLogin(component)}
                                />
                            )
                        )}
                    </Switch>

                    <InternetCheck />
                </ZimRouter>
            </CommonProviders>
        </ErrorBoundary>
    )
}

export default App
