import { fetchAndActivate, getAll } from 'firebase/remote-config'
import { useEffect, useState } from 'react'
import { REQUEST_STATUS } from '../../consts/requestStatus'
import { zimBackendRemoteConfig } from '../../firebase'
import { useAppState } from '../AppState'
import type { ContextValue, FeatureFlagMap } from './types'

const useProvider = () => {
    const [fetchFeatureFlagsRequestStatus, setFetchFeatureFlagsRequestStatus] =
        useState(REQUEST_STATUS.NOT_INITIALISED)
    const [featureFlags, setFeatureFlags] = useState<FeatureFlagMap>({})

    const { isAppLoaded, isUserLoading } = useAppState()

    const fetchFeatureFlags = async () => {
        setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.PENDING)

        try {
            await fetchAndActivate(zimBackendRemoteConfig)

            const newFeatureFlags = Object.entries(
                getAll(zimBackendRemoteConfig)
            ).reduce((acc: FeatureFlagMap, [key, value]) => {
                acc[key] = JSON.parse(value.asString())

                return acc
            }, {})

            setFeatureFlags(newFeatureFlags)
            setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.FULFILLED)
        } catch (error) {
            setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.REJECTED)
        }
    }

    useEffect(() => {
        /**
         * On the first PageLoad we go through user authentication.
         * If `isUserLoading === true` this means user authorization is
         * in progress.
         */
        if (isUserLoading)
            setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.PENDING)
        /**
         * Once ALL side-effects have completed the app is ready
         * for rendering
         */ else if (isAppLoaded) fetchFeatureFlags()
        /**
         * If none of the above cases apply, this means
         * the user has logged out and redirected into
         * login page. Thus, remove the page loader.
         */ else setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.FULFILLED)
    }, [isAppLoaded, isUserLoading])

    const value: ContextValue = featureFlags

    return {
        fetchFeatureFlagsRequestStatus,
        fetchFeatureFlags,
        value,
    }
}

export default useProvider
